<template>
  <CModal :visible="showModal" @close="() => { showModal = false }">
    <CModalHeader>
      <CModalTitle>Find In Map</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm>
        <CFormInput
            type="text"
            label="Search A Place"
            placeholder="Search..."
            v-model="searchText"
            @keyup="handleKeyup"
            aria-describedby="exampleFormControlInputHelpInline"
        />
        <CFormSelect class="mt-3" v-if="options.length > 1" aria-label="Default select example" v-model="selectedPlace">
          <option v-for="(item, index) in options" :value="item.place_id" :key="index">{{ item.display_name }}</option>
        </CFormSelect>
      </CForm>

    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { showModal = false }">
        Close
      </CButton>
      <CButton color="primary" @click="addMarkerFromFindPlace">Save changes</CButton>
    </CModalFooter>
  </CModal>

  <div id="layers">
    <select v-model="selectedLayers" @update:model-value="changeLayers($event)" class="form-multi-select" id="ms1"
            multiple data-coreui-search="true">
      <option v-for="(item, index) in availableLayers" :value="index" :key="index">{{ item.name }}</option>
    </select>
  </div>

  <div ref="map-root" style="width: 100%; height: 100%">
    <div id="custom-controls"></div>
    <div v-if="tooltipMessage" id="popup" title="myproject" class="ol-popup"><a href="#" id="popup-closer"
                                                                                class="ol-popup-closer"></a>
      <div id="popup-content">{{ this.tooltipMessage }}</div>
    </div>
  </div>

</template>

<script>
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import WMS from 'ol/source/TileWMS'
import XYZ from 'ol/source/XYZ'
import {useGeographic} from 'ol/proj';


import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Style} from "ol/style";
import * as style from "ol/style";
import * as geom from "ol/geom";
import {Feature, Overlay} from "ol";
import {Control, defaults as defaultControls, ScaleLine, Zoom, ZoomToExtent} from 'ol/control';
import ApiService from "@/core/ApiService";
import {Circle as CircleStyle} from 'ol/style';
import GoCoordinateControl from "@/controls/GoCoordinateControl";
import {GeoJSON} from "ol/format";

import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import {
  CFormSelect,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton
} from '@coreui/vue';


export default {
  name: 'MapContainer',
  components: {CFormSelect, CFormInput, CForm, CButton, CModalFooter, CModalBody, CModalTitle, CModalHeader, CModal},
  props: {},
  data() {
    return {
      loadingPlace: false,
      selectedPlace: null,
      searchText: '',
      debounceTimer: null,
      options: [],
      showModal: false,
      tooltipMessage: '!',
      last24: true,
      last5: false,
      latitude: 35.79098358945339,
      longitude: 39.17110064016026,
      accuracy: 0,
      defaultZoom: 7,
      map: null,
      markers: [],
      circles: [],
      lastQuake: [],
      lastQuakeGroup: null,
      circleGroup: null,
      disableZoom: false,
      markerGroup: null,
      controls: [
        new GoCoordinateControl({
          class: 'go-o', name: 'Yayladere', func: function () {
            this.getMap().getView().setCenter([40.0691993, 39.2262618]);
          }
        }),
        new GoCoordinateControl({
          class: 'go-b', name: 'B', func: function () {
            this.getMap().getView().setCenter([39.250473, 38.680658]);
          }
        }),
        new GoCoordinateControl({
          class: 'go-m', name: 'M', func: function () {
            this.getMap().getView().setCenter([40.497054, 38.901973]);
          }
        }),
        new GoCoordinateControl({
          class: 'go-l', name: 'L', func: function () {
            this.getMap().getView().setCenter(self.lastQuakeCoordinates);
          }
        }),
        new GoCoordinateControl({
          class: 'btn-l5', name: 'L5', func: function () {
            self.last5 = true;
            self.last24 = false;
          }
        }),

        new GoCoordinateControl({
          class: 'btn-l24', name: 'L24', func: function () {
            self.last5 = false;
            self.last24 = true;
          }
        }),
        new GoCoordinateControl({
          class: 'zoom-value', name: 'Z', func: function () {
          }
        }),
        new GoCoordinateControl({
          class: 'find', name: 'Find', func: function () {
            self.showModal = true;
          }
        }),
        new Zoom(),
        new ScaleLine()
      ],
      selectedLayers: [],
      availableLayers: [
        {
          name: 'Google Map', source: new TileLayer({
            source: new XYZ({
              url: 'https://mt0.google.com/vt/lyrs=m&hl=tr&x={x}&y={y}&z={z}',
            }),
            zIndex: 0,
            transparent: true,
          })
        },
        {
          name: 'Google Map Satellite',
          source: new TileLayer({
            source: new XYZ({
              url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            }),
            zIndex: 0,
            transparent: true,
          })
        },
        {
          name: 'Risk Haritası', source: new TileLayer({
            source: new WMS({
              url: 'https://tdthmaps.afad.gov.tr/FusionPlatform/ogc/wms/tsthwmsservice?layers=56',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 2
          })
        },
        {
          name: 'Fay Hattı 1', source: new TileLayer({
            source: new WMS({
              url: 'https://cdn.resoft.org/cdn/r/https://ivmegeoserver.afad.gov.tr/geoserver/afad/wms?SERVICE=WMS&layers=afad:fault',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 3
          })
        },
        {
          name: 'Fay Hattı 2', source: new TileLayer({
            source: new WMS({
              url: 'https://ivmegeoserver.afad.gov.tr/geoserver/afad/wms?SERVICE=WMS&layers=afad:fault_sym',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 4
          })
        },
        {
          name: 'Bölgeler',
          source: new TileLayer({
            source: new WMS({
              url: 'https://ivmegeoserver.afad.gov.tr/geoserver/afad/wms?SERVICE=WMS&layers=afad:province',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 4
          }),
        },
        {
          name: 'Bulutlar', source: new TileLayer({
            source: new XYZ({
              url: 'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=d3b46c07b4afd18734fc52d07159b5bc',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 5
          })
        },
        {
          name: 'Yağış', source: new TileLayer({
            source: new XYZ({
              url: 'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=d3b46c07b4afd18734fc52d07159b5bc',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 5
          })
        },
        {
          name: 'Deniz seviyesi basıncı', source: new TileLayer({
            source: new XYZ({
              url: 'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=d3b46c07b4afd18734fc52d07159b5bc',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 5
          })
        },
        {
          name: 'Rüzgar hızı', source: new TileLayer({
            source: new XYZ({
              url: 'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=d3b46c07b4afd18734fc52d07159b5bc',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 5
          })
        },
        {
          name: 'Sıcaklık', source: new TileLayer({
            source: new XYZ({
              url: 'https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=d3b46c07b4afd18734fc52d07159b5bc',
            }),
            format: 'image/png',
            transparent: true,
            zIndex: 5
          })
        },
      ],
      layers: [],
      circleStyle: new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "rgba(255, 0, 0, 0.5)",
          }),
        }),
      })
    }
  },
  mounted() {
    useGeographic();

    this.selectedLayers = this.getSelectedLayers()

    this.map = new Map({
      controls: defaultControls({attribution: false}).extend(this.controls),
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: this.getSelectedLayers().length > 0 ? this.getSelectedLayers().map((item) => this.availableLayers[item].source) : this.availableLayers.filter((item, index) => {
        return index !== 1 && index < 8
      }).map((item) => {
        return item.source
      }),

      // the map view will initially show the whole world
      view: new View({
        zoom: this.defaultZoom,
        center: [this.latitude, this.longitude],
        // constrainResolution: true,
        smoothResolutionConstraint: false,
        // zoomFactor: 5
      }),
    })

    self = this;

    this.popup = new Overlay({
      element: document.getElementById('popup'),
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.map.addOverlay(this.popup);

    this.map.on("moveend", function (e) {
      self.latitude = self.map.getView().getCenter()[0];
      self.longitude = self.map.getView().getCenter()[1];
      self.defaultZoom = self.map.getView().getZoom();
    })

    this.map.getView().on('change:resolution', (event) => {
      document.getElementsByClassName('zoom-value')[0].innerHTML = '<button>Z' + this.map.getView().getZoom().toFixed(2) + '</button>';
    });

    this.map.on('click', function (evt) {
      self.showTooltip(evt);
    });
    this.map.on('pointermove', function (evt) {
      self.showTooltip(evt);
    });

    this.initialize();

    setTimeout(this.getLast24, 100)
    setInterval(this.getLast24, 10000);

    setTimeout(this.getLast5, 100)
    setInterval(this.getLast5, 10000);

  },

  methods: {
    getSelectedLayers() {
      return localStorage.getItem('selectedLayers') && JSON.parse(localStorage.getItem("selectedLayers")).length > 0 ? JSON.parse(localStorage.getItem('selectedLayers')) : []
    },
    initialize() {
      this.addMarkerGroup([39.250473, 38.680658])
      this.addMarkerGroup([40.497054, 38.901973])
      this.addMarkerGroup([40.0691993, 39.2262618])
      this.addMarkerGroup([39.7478168, 38.978196])

      navigator.geolocation.getCurrentPosition(function (location) {
        self.latitude = location.coords.latitude;
        self.longitude = location.coords.longitude;
        self.accuracy = location.coords.accuracy;
        self.addMarkerGroup([self.longitude, self.latitude])
        self.map.getView().setCenter([self.longitude, self.latitude])
      });

    },
    changeLayers() {
      this.map.getLayers().forEach(layer => {
        this.map.removeLayer(layer)
      })

      let layerList = []

      if (this.selectedLayers.length > 0) {
        for (let i = 0; i < this.selectedLayers.length; i++) {
          layerList.push(this.availableLayers[this.selectedLayers[i]].source)
        }
      } else {
        layerList.push(this.availableLayers[0].layer)
      }

      localStorage.setItem('selectedLayers', JSON.stringify(this.selectedLayers))

      this.map.setLayers(layerList)

      this.markerGroup.getSource().clear();
      this.map.removeLayer(this.markerGroup);
      this.initialize();
    },

    addMarkerFromFindPlace() {
      if (!this.selectedPlace) {
        return false;
      }


      const selectedPlace = this.options.find((item) => {
        return item.place_id === parseInt(this.selectedPlace)
      })

      this.addMarkerGroup([selectedPlace.lon, selectedPlace.lat])
      this.map.getView().setCenter([selectedPlace.lon, selectedPlace.lat])
      this.options = [{display_name: 'Loading...', place_id: ''}];
      this.searchText = ''
      this.showModal = false;
    },
    handleKeyup() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getOptions()
      }, 500);
    },
    getOptions() {
      this.selectedPlace = null;
      this.options = [{display_name: 'Loading...', place_id: ''}];
      ApiService.get("https://nominatim.openstreetmap.org/search?q=" + this.searchText + "&format=json").then((response) => {
        this.options = [{display_name: 'Select', place_id: ''}, ...response.data];
      });
    },

    showTooltip(evt) {
      var feature = self.map.forEachFeatureAtPixel(evt.pixel, function (feat) {
            return feat;
          }
      );

      // if (feature && feature.get('type') == 'Point') {
      if (feature) {
        var coordinate = evt.coordinate;    //default projection is EPSG:3857 you may want to use ol.proj.transform
        self.tooltipMessage = feature.get('desc');
        if (self.tooltipMessage === "" || self.tooltipMessage === "undefined") {
          self.popup.setPosition(undefined);
        } else {
          self.popup.setPosition(coordinate);
        }
      } else {
        self.popup.setPosition(undefined);
      }
    },
    addMarkerGroup(coordinate) {
      this.map.removeLayer(this.markerGroup);
      let marker = new Feature({
        geometry: new geom.Point(coordinate),
      });
      marker.setStyle(
          new Style({
            image: new style.Icon({
              src: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png",
            }),
          })
      );

      this.markers.push(marker);

      let vectorSource = new VectorSource({
        features: this.markers,
      });
      this.markerGroup = new VectorLayer({
        source: vectorSource,
        zIndex: 6
      });
      this.map.addLayer(this.markerGroup);
    },

    addLastQuake(coordinate) {
      this.lastQuake = [];
      this.map.removeLayer(this.lastQuakeGroup);

      const baseRadius = 1;
      const numberOfCircles = 5;
      const gapBetweenCircles = 5;
      const innerCircleRadii = Array.from(
          {length: numberOfCircles},
          (_, index) => baseRadius * (index + 2) + gapBetweenCircles * index
      );


      innerCircleRadii.forEach(radius => {

        let marker = this.getCircleMarker(coordinate, radius - 1, 'rgba(255,255,255)', 1)
        this.lastQuake.push(marker)
        marker = this.getCircleMarker(coordinate, radius, 'rgba(255,0,0)', 3)
        this.lastQuake.push(marker)
        marker = this.getCircleMarker(coordinate, radius + 1, 'rgba(255,255,255)', 1)
        this.lastQuake.push(marker)


      });

      let vectorSource = new VectorSource({
        features: this.lastQuake,
      });
      this.lastQuakeGroup = new VectorLayer({
        source: vectorSource,
        zIndex: 7
      });

      this.map.addLayer(this.lastQuakeGroup);
    },

    getCircleMarker(coordinate, radius, color, width) {
      const marker = new Feature({
        geometry: new geom.Point(coordinate),
      });

      const innerCircleStyle = new Style({
        image: new CircleStyle({
          radius: radius - 1,
          stroke: new style.Stroke({
            color: color,
            width: width,
          }),
        }),
      });

      marker.setStyle(innerCircleStyle)
      return marker;
    },

    addButton(text, handler) {
      let button = document.createElement('button');
      button.innerHTML = text;
      button.addEventListener('click', handler, false);
      button.addEventListener('touchstart', handler, false);
      let element = document.createElement('div');
      element.className = 'ol-unselectable ol-control';
      element.appendChild(button);
      return element;
    },

    getLast24() {
      if (this.last24) {
        ApiService.get("https://map-api.resoft.org/api/v1/Get24HEvents").then((response) => {
          let parameters = response.data
          this.lastQuakeCoordinates = [parameters[0].longitude, parameters[0].latitude];

          this.addLastQuake(this.lastQuakeCoordinates)
          parameters.sort((a, b) => b.magnitude - a.magnitude);
          this.addCircle(response.data)
        });
      }
    },

    getLast5() {
      if (this.last5) {
        ApiService.get("https://map-api.resoft.org/api/v1/GetLast5Events").then((response) => {
          let parameters = response.data
          this.lastQuakeCoordinates = [parameters[0].longitude, parameters[0].latitude];

          this.addLastQuake(this.lastQuakeCoordinates)
          parameters.sort((a, b) => b.magnitude - a.magnitude);
          this.addCircle(response.data)
        });
      }
    },

    addCircle(data) {
      this.map.removeLayer(this.circleGroup);
      this.circles = [];

      let lastLat = data[0].latitude
      let lastLong = data[0].longitude

      data.map((item) => {
        let circle = new Feature({
          geometry: new geom.Circle([item.longitude, item.latitude], (Math.exp(item.magnitude / 1.01 - 0.33)) / (100)),
          desc: item.magnitude + "\n" + this.getDate(item.eventDate) + "\n" + item.depth + "\n" + item.location,
        });

        const circleStyle = new Style({
          stroke: new style.Stroke({
            color: '#41ff00',
            width: 1,
          }),
          fill: new style.Fill({
            color: 'rgba(0,0,0,0.4)',
          }),
        });
        circle.setStyle(circleStyle);

        this.circles.push(circle);
      })

      let vectorSource = new VectorSource({
        features: this.circles,
      });
      this.circleGroup = new VectorLayer({
        source: vectorSource,
        zIndex: 5
      });
      this.map.addLayer(this.circleGroup);
    },

    getDate(strDate) {
      return strDate
    },
  }
}
</script>

<style>
.ol-popup {
  position: absolute;
  background-color: white;
  /*--webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));*/
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 180px;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-control button {
  width: 3.5em;
  height: 2.375em;
}

#layers {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 10em;
  height: 10em;
  z-index: 99;
}

#layers select {
  height: 20em;
}

@media (max-width: 480px) {
  .ol-control button {
    width: 2.5em;
    height: 2em;
    font-weight: normal;
    font-size: 1em !important;
  }

  #layers select {
    height: 2.5em;
  }
}

.go-o {
  top: 5.5em;
  left: 0.5em;
}

.go-b {
  top: 8em;
  left: 0.5em;
}

.go-m {
  top: 10.5em;
  left: 0.5em;
}

.go-l {
  top: 13em;
  left: 0.5em;
}

.btn-l5 {
  top: 15.5em;
  left: 0.5em;
}

.btn-l24 {
  top: 18em;
  left: 0.5em;
}

.zoom-value {
  top: 20.5em;
  left: 0.5em;
}

.find {
  top: 23em;
  left: 0.5em;
}
</style>
