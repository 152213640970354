import {Control} from "ol/control";

class GoCoordinateControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = options.name;

        const element = document.createElement('div');
        element.className = options.class + ' ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', options.func.bind(this), false);
    }
}

export default GoCoordinateControl;