<template>
  <div style="height:100%; width:100%">
    <l-map :useGlobalLeaflet="false" ref="map" v-model:zoom="defaultZoom" :center="[latitude, longitude]"  @update:center="centerUpdate" @update:zoom="zoomUpdate">
      <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
      >
      </l-tile-layer>
    <l-marker lat-lng="latLng(item)" v-for="item in markers"></l-marker>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker, LTooltip} from "@vue-leaflet/vue-leaflet";
import {latLng} from "leaflet/src/geo";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data() {
    return {
      tooltipMessage: '!Testt',
      last24: true,
      last5: false,
      latitude: 38.9019343,
      longitude: 40.4973295,
      accuracy: 0,
      defaultZoom: 15,
      map: null,
      markers: [],
      circles: [],
      lastQuake: [],
      lastQuakeGroup: null,
      circleGroup: null,
      markerGroup: null,
    };
  },

  mounted() {
    self = this;
    navigator.geolocation.getCurrentPosition(function (location) {
      self.latitude = location.coords.latitude;
      self.longitude = location.coords.longitude;
      self.accuracy = location.coords.accuracy;
      self.addMarkerGroup([self.latitude, self.longitude])
      // self.map.getView().setCenter([self.longitude, self.latitude])
    });
  },

  methods: {
    zoomUpdate(zoom) {
      // let markers = this.markers;
      // this.markers = [];
      // LMap.markers.clear();
      // markers.forEach(function (item) {
      //   this.addMarkerGroup(item)
      // });
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    addMarkerGroup(coordinates) {
      this.markers.push(latLng(coordinates));
      // this.markerGroup = L.layerGroup(this.markers).addTo(this.map);
    },
  }
};
</script>

<style></style>